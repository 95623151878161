@import "functions";
@import "variables";
@import "mixins";

@import "bootstrap/scss/bootstrap-reboot.scss";
@import "bootstrap/scss/bootstrap-grid.scss";
@import "bootstrap/scss/_mixins.scss";
@import "bootstrap/scss/_buttons.scss";
@import "bootstrap/scss/_card.scss";
@import "bootstrap/scss/_forms.scss";
@import "bootstrap/scss/_list-group.scss";
@import "bootstrap/scss/_tables.scss";
@import "bootstrap/scss/_type.scss";
@import "bootstrap/scss/_utilities.scss";

@import "typography";
@import "header";
@import "components";
@import "transitions";

main {
  @include section-padding;
  min-height: 100vh;
}

input:focus,
button:focus {
  outline: none;
  box-shadow: $input-focus-box-shadow;
  transition: box-shadow 0.25s linear;
}
