.bookmark-excerpt {
  position: relative;
  display: block;
  margin-left: 20px;

  a {
    color: inherit;

    &:hover {
      text-decoration: none;
      color: inherit;
    }
  }

  .favicon {
    display: block;
    width: 16px;
    height: 16px;
    float: left;
    margin: 6px 8px 6px -20px;
    object-fit: contain;
  }

  .domain {
    display: inline-block;
    margin-bottom: 0.75rem;
    font-size: rem(12px);
    font-weight: $font-weight-medium;
    color: $grey;
  }

  h2 {
    margin-bottom: 1.25rem;
    font-size: rem(18px);
    font-weight: $font-weight-bold;
  }

  p {
    font-size: rem(14px);
    color: $darker-grey;
  }

  ul.tags {
    display: flex;
    flex-flow: row wrap;
    margin: 0;
    padding: 0;
    list-style: none;

    a {
      display: block;
      margin: 0 0.5rem 0.5rem 0;
      padding: 4px 16px;
      background-color: $lightest-grey;
      border-radius: 20px;
      line-height: 1;
      font-weight: $font-weight-medium;
      font-size: rem(11px);
      color: $grey;
      transition: background-color 0.25s linear;

      &:hover {
        text-decoration: none;
        background-color: $lighter-grey;
      }
    }
  }

  &:hover .actions button {
    color: $dark-grey;
    transition: background-color 0.125s linear, color 0.125s linear;

    &:nth-child(1) {
      transition: background-color 0.125s linear, color 0.125s linear 0s;
    }
    &:nth-child(2) {
      transition: background-color 0.125s linear, color 0.125s linear 0.05s;
    }
    &:nth-child(3) {
      transition: background-color 0.125s linear, color 0.125s linear 0.1s;
    }
    &:nth-child(4) {
      transition: background-color 0.125s linear, color 0.125s linear 0.15s;
    }
  }

  .actions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    margin: 1.5rem 10px 0 0;

    button {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      margin-left: 1rem;
      padding: 0;
      border-radius: 50%;
      color: $grey;
      background-color: $lighter-grey;
      border: none;
      appearance: none;
      transition: background-color 0.25s linear, color 0.25s linear;

      &:hover {
        background-color: $lighter-grey;
        color: $darker-grey;
      }

      svg {
        display: block;
        width: 14px;
        height: 14px;
      }
    }
  }

  @include md {
    .favicon {
      margin-left: -30px;
    }

    .actions button {
      color: $light-grey;
      background-color: $lightest-grey;
    }
  }

  @include lg {
    .actions button {
      width: 40px;
      height: 40px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.bookmark-list {
  .fade-static-exit ~ .bookmark-excerpt {
    pointer-events: none;
    transform: translateY(calc(-1 * var(--exiting-bookmark-height)));
    transition: transform 0.5s $snappy;
  }
}


ul.common-tags {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    position: relative;
    margin: 0 0.5rem 0.5rem 0;
  }

  a {
    display: block;
    padding: 4px 16px;
    background-color: $lightest-grey;
    border-radius: 20px;
    line-height: 1;
    font-weight: $font-weight-medium;
    font-size: rem(11px);
    color: $grey;
    transition: background-color 0.25s linear;

    &:hover {
      text-decoration: none;
      background-color: $lighter-grey;
    }

    span {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      height: 100%;;
      background-color: darken($lightest-grey, 1%);
      border-radius: 20px;
      mix-blend-mode: multiply;
    }
  }
}