header {
  position: relative;
  padding-top: 20px;
  border-bottom: 1px solid $lighter-grey;

  .branding {
    display: inline-flex;
    align-items: center;
    font-size: rem(24px);
    font-weight: $font-weight-bold;
    color: $black;

    &:hover {
      text-decoration: none;
    }

    img {
      display: inline-block;
      margin-right: 1rem;
    }
  }

  ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin-right: 1rem;
    }

    a {
      display: block;
      padding: 0.5rem;
      font-weight: $font-weight-medium;
      font-size: rem(14px);
      color: $black;

      &:hover {
        text-decoration: none;
      }

      &.active {
        border-bottom: 2px solid $black;
      }
    }
  }

  .avatar {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    float: right;
  }

  @include md {
    padding-top: 40px;

    ul {
      margin: 10px -0.5rem 0;
    }

    .avatar {
      width: 60px;
      height: 60px;
    }
  }

  @include lg {
    padding-top: 60px;

    ul {
      margin: 20px -0.5rem 0;
    }
  }
}

.search-form {
  position: relative;
  margin: 20px 0 15px;

  &:focus-within img {
    opacity: 1;
  }

  img {
    position: absolute;
    left: 20px;
    top: 12px;
    display: block;
    width: 16px;
    height: 16px;
    opacity: 0.5;
    transition: opacity 0.25s linear;
  }

  input {
    display: block;
    width: 100%;
    height: 40px;
    padding: 10px 30px 10px 70px;
    appearance: none;
    background-color: $lightest-grey;
    border: 1px solid $lighter-grey;
    border-radius: 30px;
    line-height: 40px;
  }

  @include md {
    width: calc(100% + 30px);
    margin: 0 0 0 -30px;

    img {
      left: 30px;
      top: 20px;
      width: 20px;
      height: 20px;
    }

    input {
      height: 60px;
      padding: 10px 30px 10px 70px;
    }
  }

  @include lg {

  }
}
