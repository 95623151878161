// Colors
$white:                     #FFFFFF;
$lightest-grey:             #F4F4F4;
$lighter-grey:              #E5E5E5;
$light-grey:                #A5B6C0;
$grey:                      #64869B;
$dark-grey:                 #456579;
$darker-grey:               #264457;
$darkest-grey:              #072335;
$black:                     #000000;

$darker-blue:               #072335;
$dark-blue:                 #0A3751;
$blue:                      #00C6FF;
$red:                       #FF6F8C;
$yellow:                    #FFE268;
$green:                     #00FFB8;

$primary:                   $red;
$secondary:                 $blue;

$body-bg:                   $white;
$body-color:                $black;

$font-family-sans-serif:    'Inter', sans-serif;;
$font-family-monospace:     source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
$font-family-base:          $font-family-sans-serif;

$font-weight-regular:       400;
$font-weight-medium:        500;
$font-weight-bold:          700;

$font-size-base:            1rem;
$font-weight-base:          $font-weight-regular;
$line-height-base:          1.5;

$paragraph-margin-bottom:   1.5rem;
$form-group-margin-bottom:  1.5rem;
$label-margin-bottom:       0.25rem;
$input-border-radius:       0;
$input-padding-y:           0.5rem;
$input-padding-x:           1rem;

$headings-margin-bottom:    1.5rem;
$headings-font-family:      $font-family-sans-serif;
$headings-font-weight:      $font-weight-medium;
$headings-line-height:      1.125;

$h1-font-size:              rem(36px);
$h2-font-size:              rem(24px);
$h3-font-size:              rem(20px);
$h4-font-size:              rem(18px);
$h5-font-size:              rem(18px);
$h6-font-size:              rem(18px);

$lead-font-size:            1.25rem;

$btn-padding-y:             0.5rem;
$btn-padding-x:             1.125rem;
$btn-font-size:             rem(14px);
$btn-font-weight:           $font-weight-bold;
$btn-font-family:           $font-family-sans-serif;
$btn-border-radius:         0;

$transition-fade:           opacity 0.25s;

// Transitions
$zippy:                     cubic-bezier(0.19, 0.01, 0, 0.99);
$snappy:                    cubic-bezier(0.47, 0.03, 0.29, 1);
$ease-in:                   cubic-bezier(0, 0, 0.79, 0.15);
$ease-out:                  cubic-bezier(0, 0.5, 0.5, 1);

$small-shadow:              0 0 6px rgba(0,0,0,0.15);

// Bootstrap Overrides
$spacers: (
    5: 5px,
    10: 10px,
    15: 15px,
    20: 20px,
    25: 25px,
    30: 30px,
    35: 35px,
    40: 40px,
    45: 45px,
    50: 50px,
    60: 60px,
    70: 70px,
    75: 75px,
    80: 80px,
    90: 90px,
    100: 100px,
    110: 110px,
    120: 120px
);

$grid-gutter-width: 40px;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1440px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px
);